.newforma-checkboxContainer .checkbox {
  font-weight: 800px;
}
.newforma-checkboxContainer .checkbox .ms-Checkbox-checkbox {
  border-color: var(--dk-gray-2_white);
}
.newforma-checkboxContainer .checkbox [aria-checked="true"] + .ms-Checkbox-label .ms-Checkbox-checkbox {
  border-color: var(--md-blue-2_lt-blue-2);
}
.newforma-checkboxContainer .checkbox:hover [aria-checked="mixed"] + .ms-Checkbox-label .ms-Checkbox-checkbox {
  border-color: var(--md-blue-3_lt-blue-3);
}
.newforma-checkboxContainer .checkbox:hover .ms-Checkbox-checkbox {
  border-color: var(--dk-gray-2_lt-blue-2);
}
.newforma-checkboxContainer .checkbox:hover .ms-Checkbox-checkbox::after {
  border-color: var(--md-blue-3_lt-blue-3);
}
.custom-tooltip-callout {
  height: auto !important;
}
.custom-tooltip-callout .ms-Callout-main,
.custom-tooltip-callout .ms-Dropdown-callout {
  box-shadow: none !important;
  border: none !important;
}
.tooltip-content {
  white-space: normal !important;
  font-size: 12px !important;
  max-width: 260px !important;
  padding: 2px !important;
}
.tooltip-bold {
  font-weight: bold !important;
}
.custom-bubble-callout {
  height: auto !important;
}
.custom-bubble-callout .ms-TeachingBubble {
  background-color: var(--button-active) !important;
}
.custom-bubble-callout .ms-Callout-main,
.custom-bubble-callout .ms-Dropdown-callout {
  box-shadow: none !important;
  border: none !important;
}
.custom-close-button {
  margin-top: 22px;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 2px;
  font-size: 14px;
  font-weight: 600;
  width: 75px;
  border: none !important;
}
.page-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  pointer-events: all;
}
