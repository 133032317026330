.newforma-attachmentSelected {
  background-color: var(--background-selected-color);
}
.newforma-hidden {
  visibility: hidden;
}
.newforma-none {
  display: none;
}
.ms-Icon {
  color: var(--md-blue-2_lt-blue-2);
}
.ms-SearchBox-icon {
  color: var(--md-blue-2_lt-blue-2);
}
* {
  margin: 0;
  padding: 0;
}
html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: Roboto, sans-serif;
}
html ::-ms-clear,
body ::-ms-clear {
  display: none;
}
:root {
  --white_white: #ffffff;
  --white_lt-gray-6: #ffffff;
  --white_lt-gray-9: #ffffff;
  --white_dk-gray-1: #434342;
  --white_dk-gray-5: #ffffff;
  --white_lt-blue-2: #ffffff;
  --white_black: #ffffff;
  --lt-gray-1_dk-gray-1: #f4f5f7;
  --lt-gray-1_dk-gray-1-alpha-49: #f4f5f7;
  --lt-gray-1_dk-gray-3: #f4f5f7;
  --lt-gray-1_dk-gray-6: #f4f5f7;
  --lt-gray-2_white-alpha-15: #f3f2f1;
  --lt-gray-2_lt-gray-2: #f3f2f1;
  --lt-gray-2_lt-gray-8-alpha-29: #f3f2f1;
  --lt-gray-2_dk-gray-1: #f3f2f1;
  --lt-gray-2_dk-gray-2: #f3f2f1;
  --lt-gray-2_dk-gray-3: #f3f2f1;
  --lt-gray-2_dk-gray-4: #f3f2f1;
  --lt-gray-3_black: #f4f4f4;
  --lt-gray-4_md-gray-6: #eaeaea;
  --lt-gray-5_md-gray-6: #edebe9;
  --lt-gray-5_dk-gray-2: #edebe9;
  --lt-gray-7_md-gray-6: #d8d8d8;
  --lt-gray-7_dk-gray-1: #d8d8d8;
  --lt-gray-7_black: #d8d8d8;
  --lt-gray-8_dk-gray-1: #e1dfdd;
  --md-gray-1_md-gray-1: #bdbdbd;
  --md-gray-3_white: #a19f9d;
  --md-gray-3_md-gray-3: #a19f9d;
  --md-gray-3_dk-gray-1: #a19f9d;
  --md-gray-4_white: #9d9d9d;
  --md-gray-5_white: #8a898a;
  --md-gray-5_md-gray-2: #8a898a;
  --md-gray-5_md-gray-5: #8a898a;
  --md-gray-6_md-gray-6: #626262;
  --md-gray-7_lt-gray-9: #605e5c;
  --md-gray-7_dk-gray-5: #605e5c;
  --md-gray-7_lt-blue-2: #605e5c;
  --md-gray-8_white: #4e5860;
  --md-gray-8_md-gray-2: #4e5860;
  --md-gray-10_lt-gray-14: #D0D0D0;
  --md-gray-12_lt-gray-15: #4F4F4F;
  --dk-gray-2_white: #323130;
  --dk-gray-2_lt-gray-6: #323130;
  --dk-gray-2_lt-blue-2: #323130;
  --dk-gray-5_dk-gray-5: #1e1d1e;
  --black_white: #000000;
  --black_dk-gray-5: #000000;
  --black_black: #000000;
  --lt-blue-1_dk-gray-1: #e0f1fe;
  --transparent_transparent: #ffffff;
  --lt-blue-4_lt-blue-4: #c7e0f4;
  --md-blue-1_white: #4d86a0;
  --md-blue-1_lt-blue-2: #4d86a0;
  --md-blue-2_lt-blue-2: #0177d4;
  --md-blue-2_md-blue-2: #0177d4;
  --md-blue-3_lt-blue-3: #005a9e;
  --md-blue-3_dk-gray-1: #005a9e;
  --dk-blue-1_dk-blue-1: #003c4d;
  --lt-red-2_lt-red-2: #ff0000;
  --md-red-1_md-red-1: #d83b01;
  --dk-red-1_lt-red-1: #a4262c;
  --header-height: 32px;
  --footer-height: 60px;
  --dropdown-box-shadow: rgba(0, 0, 0, 0.133) 0px 6.4px 14.4px 0px, rgba(0, 0, 0, 0.11) 0px 1.2px 3.6px 0px;
  --custom-border: none;
  --reminder-shadow-light: #f3f2f1;
  --reminder-shadow-dark: #C8C6C4;
  --reminder-shadow-border: #C8C6C4;
  --reminder-thumb-inactive: #C8C6C4;
  --reminder-thumb-active: #f3f2f1;
  --disabled-arrow-color: #a19f9d;
  --disabled-spin-box-color: #f3f2f1;
  --reminder-font-and-number: #a19f9d;
  --clear-filing: #a19f9d;
  --toolbar-background: #F5F5F5;
  --toolbar-color: #434342;
  --button-hover: rgba(217, 217, 217, 0.6);
  --toolbar-focus: #D9D9D9;
  --popup-hover: rgba(217, 217, 217, 0.6);
  --button-active: #0078D4;
  --editor-border-color: #a19f9d;
  --toolbar-divider: #E3E3E3;
  --invalid-error: #A4262C;
}
[data-theme='dark'] {
  --white_white: #ffffff;
  --white_lt-gray-6: #e7e7e7;
  --white_lt-gray-9: #c4c4c4;
  --white_dk-gray-1: #ffffff;
  --white_dk-gray-5: #1e1d1e;
  --white_lt-blue-2: #75b6e7;
  --white_black: #000000;
  --lt-gray-1_dk-gray-1: #434342;
  --lt-gray-1_dk-gray-1-alpha-49: #4343427d;
  --lt-gray-1_dk-gray-3: #242324;
  --lt-gray-1_dk-gray-6: #2c2a2b;
  --lt-gray-2_white-alpha-15: rgba(255, 255, 255, 0.15);
  --lt-gray-2_lt-gray-2: #f3f2f1;
  --lt-gray-2_lt-gray-8-alpha-29: rgba(217, 217, 217, 0.29);
  --lt-gray-2_dk-gray-1: #434342;
  --lt-gray-2_dk-gray-2: #323130;
  --lt-gray-2_dk-gray-3: #242324;
  --lt-gray-2_dk-gray-4: #2d2a2d;
  --lt-gray-3_black: #000000;
  --lt-gray-4_md-gray-6: #626262;
  --lt-gray-5_md-gray-6: #626262;
  --lt-gray-5_dk-gray-2: #323130;
  --lt-gray-7_md-gray-6: #626262;
  --lt-gray-7_dk-gray-1: #434342;
  --lt-gray-7_black: #000000;
  --lt-gray-8_dk-gray-1: #434342;
  --md-gray-1_md-gray-1: #bdbdbd;
  --md-gray-3_white: #ffffff;
  --md-gray-3_md-gray-3: #a19f9d;
  --md-gray-3_dk-gray-1: #434342;
  --md-gray-4_white: #ffffff;
  --md-gray-5_white: #ffffff;
  --md-gray-5_md-gray-2: #a3a3a3;
  --md-gray-5_md-gray-5: #8a898a;
  --md-gray-6_md-gray-6: #626262;
  --md-gray-7_lt-gray-9: #c4c4c4;
  --md-gray-7_dk-gray-5: #1e1d1e;
  --md-gray-7_lt-blue-2: #75b6e7;
  --md-gray-8_white: #ffffff;
  --md-gray-8_md-gray-2: #a3a3a3;
  --md-gray-10_lt-gray-14: #525252;
  --md-gray-12_lt-gray-15: #CBCBCB;
  --dk-gray-2_white: #ffffff;
  --dk-gray-2_lt-gray-6: #e7e7e7;
  --dk-gray-2_lt-blue-2: #75b6e7;
  --dk-gray-5_dk-gray-5: #1e1d1e;
  --black_white: #ffffff;
  --black_dk-gray-5: #1e1d1e;
  --black_black: #000000;
  --lt-blue-1_dk-gray-1: #434342;
  --transparent_transparent: #434342;
  --lt-blue-4_lt-blue-4: #c7e0f4;
  --md-blue-1_white: #ffffff;
  --md-blue-1_lt-blue-2: #75b6e7;
  --md-blue-2_lt-blue-2: #75b6e7;
  --md-blue-2_md-blue-2: #0177d4;
  --md-blue-3_lt-blue-3: #5495c7;
  --md-blue-3_dk-gray-1: #434342;
  --dk-blue-1_dk-blue-1: #003c4d;
  --lt-red-2_lt-red-2: #ff0000;
  --md-red-1_md-red-1: #d83b01;
  --dk-red-1_lt-red-1: #f14250;
  --lt-gray-2_lt-yellow-1: #FFF4CE;
  --header-height: 32px;
  --footer-height: 60px;
  --reminder-shadow-light: #1e1d1e;
  --reminder-shadow-dark: #000000;
  --reminder-shadow-border: #525252;
  --reminder-thumb-inactive: #525252;
  --reminder-thumb-active: #525252;
  --disabled-arrow-color: #a19f9d;
  --disabled-spin-box-color: #000000;
  --reminder-font-and-number: #525252;
  --clear-filing: #525252;
  --toolbar-background: #323130;
  --toolbar-color: #F5F5F5;
  --button-hover: rgba(0, 0, 0, 0.5);
  --toolbar-focus: #000000;
  --popup-hover: #4E4E4E;
  --button-active: #76B6E6;
  --editor-border-color: #434342;
  --toolbar-divider: #434342;
  --invalid-error: #EB4250;
}
.newforma-checkbox {
  padding-right: 5px;
  flex-shrink: 0;
  width: 24px;
}
.newforma-checkbox .ms-Checkbox-checkbox {
  border-color: var(--dk-gray-2_lt-blue-2);
}
.newforma-checkbox [aria-checked="mixed"] + .ms-Checkbox-label .ms-Checkbox-checkbox {
  border-color: var(--md-blue-2_lt-blue-2);
}
.newforma-checkbox:hover [aria-checked="mixed"] + .ms-Checkbox-label .ms-Checkbox-checkbox {
  border-color: var(--md-blue-3_lt-blue-3);
}
.newforma-checkbox:hover .ms-Checkbox-checkbox {
  border-color: var(--dk-gray-2_lt-blue-2);
}
.newforma-checkbox:hover .ms-Checkbox-checkbox::after {
  border-color: var(--md-blue-3_lt-blue-3);
}
.newforma-hintText {
  padding: 5px;
  word-break: break-word;
}
.newforma-appContainer {
  overflow: hidden;
  height: 100%;
  width: 100%;
}
.newforma-flexColumn {
  -ms-box-orient: horizontal;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -moz-flex;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
  flex-basis: auto;
  justify-content: flex-start;
  flex-grow: 1;
  flex-shrink: 1;
  height: 100%;
}
.newforma-flexRow {
  -ms-box-orient: horizontal;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -moz-flex;
  display: -webkit-flex;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  flex-shrink: 1;
  width: 100%;
}
.newforma-appMain {
  background-color: var(--white_dk-gray-5);
  -ms-box-orient: horizontal;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -moz-flex;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
  flex-basis: auto;
  justify-content: flex-start;
  flex-grow: 1;
  flex-shrink: 1;
  height: 100%;
}
.newforma-fullWidth {
  width: 100%;
}
.newforma-halfWidth {
  width: 50%;
}
.newforma-importantActionButton {
  width: 230px;
  margin-bottom: 30px;
  border: none;
  padding: 0;
  color: var(--white_white);
  background-color: var(--md-red-1_md-red-1);
}
.newforma-importantActionButton :hover {
  background-color: var(--md-red-1_md-red-1);
  color: var(--white_white);
}
.newforma-importantActionButton:disabled {
  background-color: var(--lt-gray-2_lt-gray-8-alpha-29) !important;
}
.newforma-flexHeader {
  width: 100%;
}
.newforma-header {
  font-size: 15px;
  color: var(--md-blue-1_white);
  font-weight: bold;
  padding-bottom: 10px;
}
.newforma-SelectedEmailLabel {
  display: flex;
  flex-shrink: 0;
  font-weight: 700;
  font-size: 14px;
}
.toast-wrapper {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 999999;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.newforma-multi-messageBarContainer {
  background: var(--white_lt-yellow-1);
  min-height: 45px;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 5px;
}
.newforma-multi-messageBarContainer .newforma-messageBar-oneLine .ms-MessageBar-text {
  padding-top: 8px;
}
.newforma-multi-messageBarContainer .newforma-messageBar {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: auto;
  width: 100%;
  box-sizing: border-box;
}
.newforma-multi-messageBarContainer .newforma-messageBar .ms-MessageBar-content {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: auto;
  min-height: 45px;
}
.newforma-multi-messageBarContainer .newforma-messageBar .ms-MessageBar-text {
  color: var(--dk-gray-5_dk-gray-5);
}
.newforma-multi-messageBarContainer .newforma-messageBar .ms-Button-icon {
  color: var(--dk-gray-5_dk-gray-5);
  padding-top: 8px;
}
.newforma-messageBarContainer {
  position: fixed;
  top: 75px;
  z-index: 999999;
  width: 100%;
  background: var(--white_lt-yellow-1);
  min-height: 48px;
}
.newforma-messageBarContainer .newforma-messageBar-oneLine .ms-MessageBar-text {
  padding-top: 8px;
}
.newforma-messageBarContainer .newforma-messageBar {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: auto;
  width: 100%;
}
.newforma-messageBarContainer .newforma-messageBar .ms-MessageBar-content {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: auto;
  min-height: 45px;
}
.newforma-messageBarContainer .newforma-messageBar .ms-MessageBar-text {
  color: var(--dk-gray-5_dk-gray-5);
}
.newforma-messageBarContainer .newforma-messageBar .ms-Button-icon {
  color: var(--dk-gray-5_dk-gray-5);
  padding-top: 8px;
}
.newforma-messageBar-AppComponent {
  top: -3px;
}
.newforma-messageBar-AppComponent .newforma-messageBar-oneLine .ms-MessageBar-text {
  padding-top: 8px;
}
.newforma-messageBar-AppComponent .newforma-messageBar-oneLine .ms-MessageBar-icon {
  padding-top: 8px;
}
.ms-MessageBar-text {
  color: var(--dk-gray-5_dk-gray-5);
}
.ms-Callout-main,
.ms-Dropdown-callout {
  width: auto;
  min-width: 200px;
  max-width: 300px;
  box-shadow: var(--dropdown-box-shadow);
  border: var(--custom-border);
}
.ms-Callout-main .ms-Button-flexContainer,
.ms-Dropdown-callout .ms-Button-flexContainer {
  overflow: hidden;
  text-overflow: ellipsis;
}
.ms-Callout-main .ms-Button-flexContainer .ms-Button-Icon,
.ms-Dropdown-callout .ms-Button-flexContainer .ms-Button-Icon {
  color: var(--md-gray-8_white);
}
.newforma-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0;
  z-index: 999;
  position: fixed;
  width: 100%;
  height: 60px;
  text-align: center;
  background-color: var(--lt-gray-2_dk-gray-3);
  left: 0;
  right: 0;
}
.newforma-footer .newforma-footerButton {
  width: 90%;
}
.newforma-footer .newforma-footerButton.is-disabled {
  border-color: transparent;
  background-color: var(--lt-gray-2_dk-gray-4);
}
.newforma-footer .newforma-footerButton.is-disabled .ms-Button-label {
  color: var(--md-gray-3_md-gray-3);
}
.newforma-footer div:first-of-type {
  width: 90%;
}
.newforma-footer div:first-of-type :first-child {
  width: 100%;
}
.newforma-formSpacing {
  padding-bottom: 10px;
}
.newforma-form {
  padding: 0 5px 60px 5px;
  overflow-y: auto;
}
.newforma-form-filetransfer {
  padding: 0 5px 0 5px;
  overflow-y: auto;
}
.newforma-progressIndicator {
  position: absolute;
  background-color: var(--white_dk-gray-5);
  width: calc(100% - 2*24px);
  padding-left: 24px;
  padding-right: 24px;
  bottom: 60px;
}
.newforma-progressIndicator .ms-ProgressIndicator-itemName {
  font-size: 12px;
  font-weight: 600;
  padding-top: 8px;
  color: var(--black_white);
}
.newforma-progressIndicator .ms-ProgressIndicator-itemProgress {
  padding-bottom: 12px;
}
.newforma-displayBlock {
  display: block;
}
.newforma-appContainer .ms-Label {
  color: var(--black_white) !important;
}
.ms-SearchBox {
  background-color: var(--white_dk-gray-5);
}
.ms-SearchBox .ms-SearchBox-field::placeholder {
  color: var(--md-gray-5_md-gray-2);
}
.ms-BasePicker-input {
  color: var(--md-gray-8_white);
}
.ms-BasePicker-input::placeholder {
  color: var(--md-gray-5_md-gray-2) !important;
}
.ms-Dropdown:not(.is-disabled) .ms-Dropdown-title,
.ms-TextField-fieldGroup,
.ms-BasePicker-text,
.ms-TextField-field:not([disabled]) {
  border-color: var(--lt-gray-7_dk-gray-1);
  color: var(--md-gray-8_white);
  background-color: var(--white_dk-gray-5);
}
.ms-Dropdown:not(.is-disabled) .ms-Dropdown-title::placeholder,
.ms-TextField-fieldGroup::placeholder,
.ms-BasePicker-text::placeholder,
.ms-TextField-field:not([disabled])::placeholder {
  color: var(--md-gray-8_md-gray-2);
}
.ms-TextField.is-disabled .ms-TextField-fieldGroup {
  border-color: var(--lt-gray-7_black);
}
.ms-TextField.is-disabled .ms-TextField-fieldGroup {
  border-color: var(--lt-gray-7_black);
}
.ms-Dropdown.is-disabled .ms-Dropdown-title,
.ms-TextField-field[disabled] {
  background-color: var(--lt-gray-3_black);
  color: var(--md-gray-5_md-gray-5);
}
.ms-Dropdown.is-disabled .ms-Dropdown-title::placeholder,
.ms-TextField-field[disabled]::placeholder {
  color: var(--md-gray-3_dk-gray-1);
}
.ms-Dropdown.is-disabled {
  border: 1px solid var(--lt-gray-7_black);
}
.ms-Dropdown.is-disabled .ms-Dropdown-caretDown {
  color: var(--md-gray-3_dk-gray-1);
}
.newforma-specSection.disabledStyle .newforma-specSectionPicker .ms-BasePicker-text {
  background-color: var(--lt-gray-3_black);
  border-color: var(--lt-gray-7_black);
}
.ms-BasePicker-text::after {
  background-color: transparent;
}
[disabled].ms-BasePicker-input::placeholder {
  color: var(--md-gray-3_dk-gray-1) !important;
}
.ms-Panel-footer {
  background-color: var(--lt-gray-2_dk-gray-3);
  border-top: 1px solid var(--lt-gray-2_dk-gray-3);
}
.ms-Panel-main,
.ms-TextField-field {
  background-color: var(--white_dk-gray-5);
}
.ms-Panel-headerText,
.ms-Label {
  color: var(--md-gray-8_white);
}
.ms-PickerPersona-container {
  background-color: var(--lt-gray-2_dk-gray-2);
}
.ms-PickerPersona-container:hover {
  background-color: var(--lt-gray-5_md-gray-6);
}
.ms-PickerPersona-container.is-selected {
  background-color: var(--md-blue-2_lt-blue-2);
}
.ms-PickerPersona-container.is-selected .ms-Persona-primaryText,
.ms-PickerPersona-container.is-selected .ms-Button-icon {
  color: var(--white_white);
}
.ms-PickerPersona-container.is-selected:hover {
  background-color: var(--md-blue-2_lt-blue-2);
}
.ms-PickerPersona-container .ms-Persona-initials {
  color: var(--white_white);
}
.ms-PickerPersona-container .ms-Button--icon.is-disabled {
  background-color: var(--lt-gray-2_dk-gray-2);
}
.ms-PickerPersona-container .ms-Button--icon:hover {
  background-color: var(--lt-gray-7_dk-gray-1);
}
.ms-PickerPersona-container .ms-Button--icon .ms-Button-icon {
  color: var(--md-gray-8_white);
}
.ms-TagItem {
  background-color: var(--lt-gray-2_dk-gray-2);
  color: var(--dk-gray-2_white);
}
.ms-TagItem:hover {
  background-color: var(--lt-gray-5_md-gray-6);
  color: var(--dk-gray-2_white);
}
.ms-TagItem[data-is-focusable="false"] {
  background-color: var(--lt-gray-5_dk-gray-2);
  color: var(--md-gray-3_md-gray-3);
}
.ms-TagItem .ms-Button--icon.is-disabled {
  background-color: var(--lt-gray-5_dk-gray-2);
}
.ms-TagItem .ms-Button--icon.is-disabled .ms-Button-icon {
  color: var(--md-gray-3_md-gray-3);
}
.ms-TagItem .ms-Button--icon:hover {
  background-color: var(--lt-gray-8_dk-gray-1);
}
.ms-TagItem .ms-Button--icon .ms-Button-icon {
  color: var(--md-gray-8_white);
}
.ms-TagItem-close:hover {
  background: var(--md-blue-3_dk-gray-1);
}
.newforma-footer .newforma-footerButton {
  background-color: var(--md-blue-2_lt-blue-2);
  border-color: var(--md-blue-2_lt-blue-2);
}
.newforma-footer .newforma-footerButton .ms-Button-label {
  color: var(--white_black);
}
.newforma-footer .newforma-footerButton .ms-Button-label .ms-Icon {
  color: var(--white_black);
}
.newforma-footer .newforma-footerButton .is-disabled .ms-Icon {
  color: var(--md-gray-3_md-gray-3);
}
.newforma-footer .newforma-footerButton .ms-Button-flexContainer .ms-Icon.ms-Button-menuIcon {
  color: var(--md-gray-3_md-gray-3);
}
.progressBarContainer {
  color: var(--black_white);
}
.ms-TeachingBubble {
  width: 274px;
}
.ms-TeachingBubble .teachingBubblesBoldText {
  font-weight: 800;
}
.ms-TeachingBubble .ms-Button-label {
  color: #0078d4;
  font-weight: 600;
}
.ms-TeachingBubble .ms-TeachingBubble-footer .ms-TeachingBubble-primaryButton {
  background-color: white;
}
.newforma-messageBar-searchComponent {
  top: -4px;
  left: 0px;
  min-height: 48px;
}
.newforma-messageBar-searchComponent .newforma-messageBar-error .ms-MessageBar-icon {
  padding-top: 8px;
}
.newforma-sendAndFileComponent {
  -ms-box-orient: horizontal;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -moz-flex;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
  flex-basis: auto;
  justify-content: flex-start;
  flex-grow: 1;
  flex-shrink: 1;
  height: 100%;
  min-height: 0;
}
.newforma-sendAndFileComponent .newforma-sendAndFileContainer {
  min-width: 0;
  min-height: 0;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto;
  padding: 0 24px 10px 24px;
}
.newforma-sendAndFileComponent .newforma-headerHint {
  font-style: italic;
  color: var(--md-gray-5_md-gray-2);
  font-weight: 500;
}
.newforma-sendAndFileComponent .ms-Check:before {
  background-color: var(--lt-gray-1_dk-gray-1);
}
.newforma-sendAndFileComponent .ms-DetailsRow.is-selected {
  background-color: var(--lt-gray-1_dk-gray-1);
}
.newforma-sendAndFileComponent .ms-DetailsRow:focus .ms-DetailsRow-cell {
  color: var(--md-gray-8_white);
}
.newforma-sendAndFileComponent .ms-DetailsList-contentWrapper {
  background-color: var(--white_dk-gray-5);
}
